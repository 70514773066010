import React from "react"
import { graphql } from "gatsby"
import SimpleReactLightbox from "simple-react-lightbox"

import Page from "../templates"
import SEO from "../templates/seo"
import Gallery from "../components/Gallery"

export default ({ data }) => (
  <Page>
    <SEO title="Werke" />
    <h1 className="text-primary">Eine Auswahl meiner Arbeiten...</h1>
    <p className="mb-5">Viele weitere Werkte findest du in meinem Atelier!</p>
    <SimpleReactLightbox>
      <Gallery images={data.images.nodes} />
    </SimpleReactLightbox>
  </Page>
)

export const pageQuery = graphql`
  query {
    images: allContentfulArtwork(
      filter: { artist: { fistName: { eq: "Anne" } } }
      sort: { order: DESC, fields: year }
    ) {
      nodes {
        id
        title
        material
        height
        width
        year
        image {
          description
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          file {
            url
          }
        }
      }
    }
  }
`
